var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-project-list-container"},[_c('div',{staticClass:"project-list__wrapper"},[(_vm.projectTypes)?_c('div',{staticClass:"project-list-menu"},[_c('ul',{staticClass:"menu__container"},_vm._l((_vm.projectTypes),function(link){return _c('li',{key:link.id,staticClass:"menu__item",class:{
            pcHover: !_vm.$isMobile,
            typeMenuActive: Number(link.id) === Number(_vm.type)
          }},[_c('a',{staticClass:"menu__link",on:{"click":function($event){return _vm.tolink(link.id)}}},[_c('p',[_vm._v(_vm._s(link.name.en))]),_c('p',[_vm._v(_vm._s(link.name.cn))])])])}),0)]):_vm._e(),(_vm.lists)?_c('div',{staticClass:"project-list-container"},[_c('div',{staticClass:"project-list-container__wrapper"},_vm._l((_vm.lists),function(item,index){return _c('router-link',{key:index,staticClass:"project-list__item scrollObj preload",class:{
            pcHover: !_vm.$isMobile
          },attrs:{"tag":"div","to":{
            path: ("/project/" + (item.id))
          },"data-needclass":1,"data-src":_vm.$isMobile
              ? item.cover.mobile
                ? item.cover.mobile
                : item.cover.pc
              : item.cover.pc}},[_c('div',{staticClass:"item__main-body",style:({
              backgroundColor: item.bg,
              color: item.bg
            })},[(!_vm.$isMobile)?_c('div',{staticClass:"project__title"},[_c('p',{domProps:{"innerHTML":_vm._s(_vm.renderBr(item.name.en))}})]):_vm._e(),_c('div',{staticClass:"project__cover"},[_c('img',{attrs:{"src":_vm.$isMobile
                    ? item.cover.mobile
                      ? item.cover.mobile
                      : item.cover.pc
                    : item.cover.pc,"alt":""}})])]),_c('div',{staticClass:"item__name",domProps:{"innerHTML":_vm._s(_vm.renderBr(item.name.cn))}})])}),1)]):_c('div',[_vm._v("暂无数据")])])])}
var staticRenderFns = []

export { render, staticRenderFns }