<template>
  <div class="page-project-list-container">
    <div class="project-list__wrapper">
      <div class="project-list-menu" v-if="projectTypes">
        <ul class="menu__container">
          <li
            class="menu__item"
            v-for="link in projectTypes"
            :key="link.id"
            :class="{
              pcHover: !$isMobile,
              typeMenuActive: Number(link.id) === Number(type)
            }"
          >
            <a class="menu__link" @click="tolink(link.id)">
              <p>{{ link.name.en }}</p>
              <p>{{ link.name.cn }}</p>
            </a>
          </li>
        </ul>
      </div>
      <div class="project-list-container" v-if="lists">
        <div class="project-list-container__wrapper">
          <router-link
            tag="div"
            :to="{
              path: `/project/${item.id}`
            }"
            class="project-list__item scrollObj preload"
            :class="{
              pcHover: !$isMobile
            }"
            :data-needclass="1"
            :data-src="
              $isMobile
                ? item.cover.mobile
                  ? item.cover.mobile
                  : item.cover.pc
                : item.cover.pc
            "
            v-for="(item, index) in lists"
            :key="index"
          >
            <div
              class="item__main-body"
              :style="{
                backgroundColor: item.bg,
                color: item.bg
              }"
            >
              <div class="project__title" v-if="!$isMobile">
                <p v-html="renderBr(item.name.en)"></p>
              </div>
              <div class="project__cover">
                <img
                  :src="
                    $isMobile
                      ? item.cover.mobile
                        ? item.cover.mobile
                        : item.cover.pc
                      : item.cover.pc
                  "
                  alt=""
                />
              </div>
            </div>
            <div class="item__name" v-html="renderBr(item.name.cn)"></div>
          </router-link>
        </div>
      </div>
      <div v-else>暂无数据</div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import { imgPreload } from "@/assets/utils/common";
export default {
  name: "page-projects",
  props: ["type"],
  data() {
    return {
      links: [
        {
          name: {
            en: "Show Space",
            cn: "公共空间"
          }
        },
        {
          name: {
            en: "Residence",
            cn: "住宅空间"
          }
        },
        {
          name: {
            en: "Hotel Business",
            cn: "酒店商业"
          }
        }
      ],
      lists: null
    };
  },
  computed: {
    ...mapState(["projectTypes"])
  },
  watch: {
    $route: {
      handler: "init",
      immediate: true
    }
  },
  methods: {
    ...mapMutations(["PAGE_LOADING", "PAGE_LOADED"]),
    init() {
      this.PAGE_LOADING();
      this.$store.dispatch("getTypes");
      this.lists = null;
      this.getList().then(res => {
        const { lists } = res.data;
        this.lists = lists;
        if (this.lists === null) {
          this.PAGE_LOADED();
        }
        this.$nextTick(() => {
          imgPreload().then(() => {
            this.PAGE_LOADED();
          });
        });
      });
    },
    getList() {
      return this.$request.get("/api/product/list", {
        type: this.type
      });
    },
    renderBr(str) {
      return str.replace(/\n/g, "<br/>");
    },
    tolink(id) {
      if (Number(id) !== 5) {
        this.$router.push({
          path: `/projects/${id}`
        });
      }
    }
  }
};
</script>

<style></style>
